import styled from "styled-components";

export const NavP = styled.p`
  margin: 0px;
  display: inline-block;
  font-family: ${(props) => props.theme.typography.fontFamiliyTitle};
  font-size: 30px;
`;

export const BurgerP = styled.p`
  margin: 0px;
  display: inline-block;
  padding: 0px 20px;
  font-family: ${(props) => props.theme.typography.fontFamiliyTitle};
  font-size: 18px;
`;
