import React, { useState, useRef } from "react";
import FocusLock from "react-focus-lock";
import { useOnClickOutside } from "../Utils";

import BurgerContainer from "../Components/Organisms/Navigation/BurgerMenu/BurgerContainer";
import BurgerButton from "../Components/Organisms/Navigation/BurgerMenu/BurgerButton";
import { BurgerP } from "../Components/Atoms/Fonts";
import BurgerLink from "../Components/Organisms/Navigation/BurgerMenu/BurgerLink";
import BurgerTitle from "../Components/Organisms/Navigation/BurgerMenu/BurgerTitle";

const BurgerMenu = ({ className }) => {
  const [open, setOpen] = useState(false);
  const node = useRef();

  useOnClickOutside(node, () => setOpen(false));

  const checkHomeActive = () => {
    const pathname = window.location.pathname;
    return pathname === "/";
  };

  return (
    <div className={className}>
      <BurgerTitle to="/" text="Infos zur Hochzeit" />
      <BurgerTitle to="/overnight" text="Übernachtung" />
      <BurgerTitle to="/photos" text="Fotos" />
      <BurgerTitle to="/photoupload" text="Foto/Video hochladen" />
      <div ref={node}>
        <FocusLock disabled={!open}>
          <BurgerButton open={open} setOpen={setOpen} />
          <BurgerContainer open={open} setOpen={setOpen}>
            <BurgerLink
              to="/"
              isActive={checkHomeActive}
              onClick={() => setOpen(false)}
            >
              <BurgerP>Home</BurgerP>
            </BurgerLink>
            <BurgerLink to="/overnight" onClick={() => setOpen(false)}>
              <BurgerP>Übernachtung</BurgerP>
            </BurgerLink>
            <BurgerLink to="/photos" onClick={() => setOpen(false)}>
              <BurgerP>Fotos</BurgerP>
            </BurgerLink>
            <BurgerLink to="/photoupload" onClick={() => setOpen(false)}>
              <BurgerP>Fotos hochladen</BurgerP>
            </BurgerLink>
          </BurgerContainer>
        </FocusLock>
      </div>
    </div>
  );
};

export default BurgerMenu;
