import React, { useState } from "react";
import styled from "styled-components";
import background from "../Assets/marmor.png";
import Header from "../Header/Header";
import { setFile } from "../firebaseProvider";
import FileUpload from "../Components/Organisms/FileUpload/FileUpload";
import Spinner from "../Components/Atoms/Spinner";
import heic2any from "heic2any";

const PhotoUploadContainer = styled.div`
  background: linear-gradient(
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
`;

const UploadButtonContainer = styled.div`
  text-align: center;
  margin: 20px;
`;

const UploadButton = styled.button`
  border: solid ${(props) => props.theme.colors.primary} 1px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.primary};
  color: #ffffff;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  margin: 2px 2px;
  cursor: pointer;
  width: 100px;
  height: 40px;
`;

const SuccessMessage = styled.p`
  color: ${(props) => props.theme.colors.primary};
  margin: 0px;
  padding: 0px;
  text-align: center;
  font-size: 14px;
`;

const FailMessage = styled.p`
  color: red;
  margin: 0px;
  padding: 0px;
  text-align: center;
  font-size: 14px;
`;

const PaddingContainer = styled.div`
  padding-top: ${(props) => props.paddingTop};
`;

const InputContainer = styled.div`
  text-align: center;
  margin: 10px;
`;

const InputItem = styled.input`
  border: none;
  border-bottom: solid ${(props) => props.theme.colors.primary} 1px;
  padding: 6px;
`;

const PhotoUpload = () => {
  const [loading, setLoading] = useState(false);
  const [uploadData, setUploadData] = useState({ title: "", name: "" });
  const [uploadRes, setUploadRes] = useState("pending");

  const photoUploadOnChangeHandler = (event) => {
    const photoFile = event.target.files[0];
    const blobURL = URL.createObjectURL(photoFile);

    if (checkFiletype(photoFile.name)) {
      if (photoFile.name.includes(".HEIC")) {
        convertToPNG(blobURL).then((res) => {
          setUploadData({
            ...uploadData,
            photo: { file: photoFile, preview: res },
          });
        });
      } else {
        setUploadData({
          ...uploadData,
          photo: { file: photoFile, preview: blobURL },
        });
      }
    } else {
      setUploadRes("fail");
    }
  };

  const checkFiletype = (fileName) => {
    if (
      fileName.includes(".HEIC") ||
      fileName.includes(".heic") ||
      fileName.includes(".png") ||
      fileName.includes(".PNG") ||
      fileName.includes(".JPG") ||
      fileName.includes(".jpg") ||
      fileName.includes(".JPEG") ||
      fileName.includes(".jpeg") ||
      fileName.includes(".MOV") ||
      fileName.includes(".mov")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const convertToPNG = async (blobURL) => {
    const blobRes = await fetch(blobURL);
    const blob = await blobRes.blob();
    const conversionResult = await heic2any({ blob });
    return URL.createObjectURL(conversionResult);
  };

  const uploadPhoto = () => {
    if (uploadData) {
      setLoading(true);
      setUploadRes("pending");
      setFile(
        "guestImages",
        "metadata",
        uploadData.title,
        uploadData.name,
        uploadData.photo.file.name.includes(".MOV") ||
          uploadData.photo.file.name.includes(".mov")
          ? "video"
          : "photo",
        uploadData.photo.file
      )
        .then(() => {
          setLoading(false);
          setUploadRes("success");
          setUploadData({ title: "", name: "" });
        })
        .catch((err) => {
          setLoading(false);
          setUploadRes("fail");
        });
    } else {
      setUploadRes("noPhoto");
    }
  };

  return (
    <PhotoUploadContainer>
      <Header />
      <PaddingContainer paddingTop="100px">
        <FileUpload
          onUpload={photoUploadOnChangeHandler}
          photo={
            uploadData && uploadData.photo ? uploadData.photo.preview : null
          }
        />
      </PaddingContainer>
      <PaddingContainer paddingTop="15px">
        <InputContainer>
          <InputItem
            type="text"
            id="title"
            placeholder="Titel des Fotos/Videos"
            onChange={(event) =>
              setUploadData({ ...uploadData, title: event.target.value })
            }
            value={uploadData.title}
          />
        </InputContainer>
        <InputContainer>
          <InputItem
            type="text"
            id="name"
            placeholder="dein Name"
            onChange={(event) =>
              setUploadData({ ...uploadData, name: event.target.value })
            }
            value={uploadData.name}
          />
        </InputContainer>
      </PaddingContainer>
      <UploadButtonContainer>
        <UploadButton disabled={loading} onClick={uploadPhoto}>
          {loading ? <Spinner color="#ffffff" size="20px" /> : "hochladen"}
        </UploadButton>
      </UploadButtonContainer>
      {uploadRes === "success" && (
        <SuccessMessage>erfolgreich hochgeladen</SuccessMessage>
      )}
      {uploadRes === "fail" && (
        <FailMessage>
          Hochladen fehlgeschlagen. Es werden nur die Formate jpg, png, heic,
          mov akzeptiert.
        </FailMessage>
      )}
      {uploadRes === "noPhoto" && (
        <FailMessage>Wähle ein Foto aus zum Hochladen</FailMessage>
      )}
    </PhotoUploadContainer>
  );
};

export default PhotoUpload;
