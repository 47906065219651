import NavContainer from "../Components/Organisms/Navigation/NavMenu/NavContainer";
import NavLink from "../Components/Organisms/Navigation/NavMenu/NavLink";
import { NavP } from "../Components/Atoms/Fonts";

const checkHomeActive = () => {
  const pathname = window.location.pathname;
  return pathname === "/";
};

const NavMenu = ({ className }) => {
  return (
    <NavContainer className={className}>
      <NavLink to="/" isActive={checkHomeActive}>
        <NavP>Home</NavP>
      </NavLink>
      <NavLink to="/overnight">
        <NavP>Übernachtung</NavP>
      </NavLink>
      <NavLink to="/photos">
        <NavP>Fotos</NavP>
      </NavLink>
    </NavContainer>
  );
};

export default NavMenu;
