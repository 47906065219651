import React, { useState, useEffect } from "react";
import styled from "styled-components";
import background from "../Assets/marmor.png";
import qrcode from "../Assets/qrcode.png";
import { firestore } from "../firebaseProvider";

const PhotoShowContainer = styled.div`
  background: linear-gradient(
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  position: relative;
`;

const TitleContainer = styled.h1`
  margin: 0px;
  padding-top: 30px;
  text-align: center;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.typography.fontFamiliyTitle};
  font-size: 50px;
  font-weight: normal;
`;

const NameContainer = styled.h1`
  margin: 0px;
  padding-bottom: 30px;
  text-align: center;
  color: ${(props) => props.theme.colors.primary};
  font-size: 18px;
  font-weight: normal;
`;

const TitlePlaceholder = styled.div`
  padding-top: 80px;
`;

const NamePlaceholder = styled.div`
  padding-top: 48px;
`;

const QRCode = styled.img`
  width: 100px;
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

const PhotoUploadInfo = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  right: 120px;
  bottom: 20px;
`;

const PhotoUploadURL = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  right: 120px;
  bottom: 0px;
`;

const PhotoShow = () => {
  const [data, setData] = useState([]);
  const [updates, setUpdates] = useState([]);
  const [currentPhoto, setCurrentPhoto] = useState();

  useEffect(() => {
    const unsubscribe = firestore
      .collection("guestImages")
      .onSnapshot((querySnapshot) => {
        let changeData = [];
        querySnapshot.docChanges().forEach(
          (change) => {
            changeData.push(change.doc.data());
          },
          (error) => {
            console.log("error getting update", error);
          }
        );
        setUpdates(updates.concat(changeData));
      });
    return () => {
      unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const interval = setInterval(() => {
      if (updates.length !== 0) {
        const updateToShow = updates[0];
        setCurrentPhoto(updateToShow);
        setUpdates(updates.filter((item) => item !== updateToShow));
        setData([...data, updateToShow]);
      } else {
        const index = Math.floor(Math.random() * data.length);
        setCurrentPhoto(data[index]);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [updates, data, currentPhoto]);

  return (
    <PhotoShowContainer>
      {currentPhoto && (
        <>
          {currentPhoto.title ? (
            <TitleContainer>{currentPhoto.title}</TitleContainer>
          ) : (
            <TitlePlaceholder />
          )}
          {currentPhoto.name ? (
            <NameContainer>{"von " + currentPhoto.name}</NameContainer>
          ) : (
            <NamePlaceholder />
          )}
          <img
            style={{
              maxWidth: "85vw",
              maxHeight: "80vh",
              margin: "auto",
              display: "block",
            }}
            src={currentPhoto.url}
            alt="photoshow"
          />
        </>
      )}
      <PhotoUploadInfo>Foto/Video hochladen</PhotoUploadInfo>
      <PhotoUploadURL>nelinik.ch/photoupload</PhotoUploadURL>
      <QRCode src={qrcode} />
    </PhotoShowContainer>
  );
};

export default PhotoShow;

/*
{
  
}
*/
