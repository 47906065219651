import firebaseConfig from "./firebaseConfig";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const storage = firebaseApp.storage().ref();
export const firebaseAppAuth = firebaseApp.auth();
export const firestore = firebaseApp.firestore();

export const getDocument = (collection, id) => {
  return firestore
    .collection(collection)
    .doc(id)
    .get()
    .then((snapshot) => {
      return snapshot.data();
    })
    .catch((err) => console.log(err));
};

export const setDocument = (collection, id, data) => {
  return firestore.collection(collection).doc(id).set(data);
};

export const updateDocument = (collection, id, data) => {
  return firestore.collection(collection).doc(id).update(data);
};

export const deleteDocument = (collection, id) => {
  return firestore.collection(collection).doc(id).delete();
};

export const getCollection = (collection) => {
  return firestore
    .collection(collection)
    .get()
    .then((snapshot) => {
      const rawData = snapshot.docs.map((doc) => {
        const docId = { doc: doc.id };
        const docData = doc.data();
        return { ...docId, ...docData };
      });
      return rawData;
    })
    .catch((err) => console.log(err));
};

export const setFile = async (collection, id, title, name, type, file) => {
  const fileRef = storage.child(file.name);
  await fileRef.put(file);
  const fileUrl = await fileRef.getDownloadURL();

  const data = {
    title: title,
    name: name,
    type: type,
    url: fileUrl,
    timestamp: Date.now(),
  };

  return setDocument(collection, name + "_" + file.name, data);
};

export const docObserver = (collection, id) => {
  firestore
    .collection(collection)
    .doc(id)
    .onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        console.log("change: ", change.doc.data());
        return change.doc.data();
      });
    });
};
