import styled from "styled-components";

const Wrapper = styled.div`
  padding: 20px;
  background-color: #ffffff;
  margin: 20px;
`;

const Title = styled.p`
  font-size: 16px;
  text-align: center;
  font-weight: bold;
`;

const Preview = styled.img`
  width: 100%;
`;

const Description = styled.p`
  font-size: 14px;
  margin: 10px 0px;
`;

const ToWebsiteLink = styled.a`
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
`;

const Card = ({ title, imgName, descripiton, distance, price, link }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Preview src={imgName} />
      <Description>{descripiton}</Description>
      <Description>{distance}</Description>
      <Description>{price}</Description>
      <ToWebsiteLink href={link} target="_blank">
        {"zur Webseite >"}
      </ToWebsiteLink>
    </Wrapper>
  );
};

export default Card;
