import React, { useState } from "react";
import styled from "styled-components";

export const BurgerTitleContainer = styled.p`
  font-family: ${(props) => props.theme.typography.fontFamiliyTitle};
  font-size: 24px;
  margin: 0px;
  padding-top: 15px;
  padding-left: 15px;
  display: ${({ active }) => (active ? "block" : "none")};
  text-align: left;
`;

const BurgerTitle = ({ to, text }) => {
  const [active, setActive] = useState();

  const checkActive = () => {
    const pathname = window.location.pathname;
    return pathname === to;
  };

  if (active === undefined) {
    setActive(checkActive());
  }

  return <BurgerTitleContainer active={active}>{text}</BurgerTitleContainer>;
};

export default BurgerTitle;
