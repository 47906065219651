import React from "react";
import styled, { useTheme } from "styled-components";
import { BiUpload } from "react-icons/bi";

const FileUploadContainer = styled.div`
  height: 100px;
  width: 100px;
  margin: auto;
`;

const FileInput = styled.input`
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
`;

const FileInputLabel = styled.label`
  display: block;
  position: relative;
  width: 100px;
  height: 100px;
  outline: 3px dashed ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Preview = styled.img`
  max-width: 100px;
  max-height: 100px;
`;

const FileUpload = ({ onUpload, photo }) => {
  const theme = useTheme();

  return (
    <FileUploadContainer>
      <FileInput type="file" name="file" id="file" onChange={onUpload} />
      <FileInputLabel htmlFor="file">
        {photo ? (
          <Preview src={photo} />
        ) : (
          <BiUpload size={50} color={theme.colors.primary} />
        )}
      </FileInputLabel>
    </FileUploadContainer>
  );
};

export default FileUpload;
