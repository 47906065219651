import styled from "styled-components";
import Header from "../Header/Header";
import background from "../Assets/background.jpg";
import { device } from "../Components/Atoms/Devices";
import nelinik from "../Assets/nelinik.png";

const HomeContainer = styled.div`
  background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
`;

const HomeInfo = styled.div`
  background-color: rgba(255, 255, 255, 0.6);
  height: 450px;
  width: 1200px;
  position: absolute;
  left: 50%;
  margin-left: -600px;
  top: 50%;
  margin-top: -300px;

  @media ${device.tablet} {
    width: 100%;
    height: 550px;
    left: 0;
    margin-left: 0px;
    top: 20%;
    margin-top: 0px;
  }

  @media ${device.mobileL} {
    width: 100%;
    left: 0;
    height: calc(100% - 70px);
    margin-left: 0px;
    top: 100px;
    margin-top: 0px;
    background-color: transparent;
  }
`;

const PhotoContainer = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;

  @media ${device.mobileL} {
    display: none;
  }
`;

const NeliNikPhoto = styled.img`
  height: 200px;
  width: auto;
`;

const HomeInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 20px;

  @media ${device.tablet} {
    padding: 0px;
  }
`;

const HomeInfoUL = styled.ul`
  margin: 20px 0px;
  @media ${device.mobileL} {
    margin: 20px 0px 50px 0px;
  }
`;

const HomeInfoLI = styled.li`
  padding: 0px 30px 0px 0px;
  list-style-position: outside;
`;

const HomeInfoLIP = styled.p`
  font-size: 16px;
  @media ${device.mobileL} {
    font-size: 13px;
  }
`;

const HomeInfoP = styled.p`
  padding: 10px 0px 20px 0px;
  margin: 0px;
  text-align: center;
  @media ${device.mobileL} {
    display: none;
  }
`;

const HomeInfoH1 = styled.p`
  padding-top: 40px;
  display: block;
  font-size: 24px;
  text-align: center;
  margin: 0px;
  font-weight: bold;
  @media ${device.mobileL} {
    display: none;
  }
`;

//font-family: ${(props) => props.theme.typography.fontFamiliyTitle};

const Home = () => {
  return (
    <HomeContainer>
      <Header />
      <HomeInfo>
        <HomeInfoContainer>
          <HomeInfoH1>Infos zur Hochzeit</HomeInfoH1>
          <HomeInfoP>von Cornelia Scherrer & Dominik Gsponer</HomeInfoP>
          <HomeInfoUL>
            <HomeInfoLI>
              <HomeInfoLIP>
                <b>Datum & Zeit:</b> Die Hochzeit findet am 6. August 2022 statt
                und startet um 13.00 Uhr. (Trauung 14:00 Uhr)
              </HomeInfoLIP>
            </HomeInfoLI>
            <HomeInfoLI>
              <HomeInfoLIP>
                <b>Veranstalltungsort:</b> Wir feiern am und auf dem Forellensee
                in Zweisimmen. Adresse: Forellensee, Thunstrasse 32, 3770
                Zweisimmen. Webseite: www.forellensee.ch
              </HomeInfoLIP>
            </HomeInfoLI>
            <HomeInfoLI>
              <HomeInfoLIP>
                <b>Anreise:</b> Parkplätze sind genügend vorhanden. Der Bahnhof
                Zweisimmen ist ca. 15 min zu Fuss entfernt.
              </HomeInfoLIP>
            </HomeInfoLI>
            <HomeInfoLI>
              <HomeInfoLIP>
                <b>Dresscode:</b> Boho-chic wedding
              </HomeInfoLIP>
            </HomeInfoLI>
            <HomeInfoLI>
              <HomeInfoLIP>
                <b>Geschenke:</b> Grundsätzlich sind wir wunschlos glücklich und
                erwarten keine Geschenke. Falls ihr uns trotzdem etwas schenken
                wollt, freuen wir uns über einen Beitrag für die Flitterwochen.
              </HomeInfoLIP>
            </HomeInfoLI>
            <HomeInfoLI>
              <HomeInfoLIP>
                <b>Trauzeuginnen:</b> Viktoria Klaus: 076 379 26 91, Adeline
                Tomaschett: 078 699 77 63
              </HomeInfoLIP>
            </HomeInfoLI>
          </HomeInfoUL>
        </HomeInfoContainer>
      </HomeInfo>
      <PhotoContainer>
        <NeliNikPhoto src={nelinik} alt="Photo" />
      </PhotoContainer>
    </HomeContainer>
  );
};

export default Home;
