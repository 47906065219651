const firebaseConfig = {
  apiKey: "AIzaSyBxOj3Lutvr1hH864gmDyntRkiYMVmeo2s",
  authDomain: "wedding-page-d37fc.firebaseapp.com",
  projectId: "wedding-page-d37fc",
  storageBucket: "wedding-page-d37fc.appspot.com",
  messagingSenderId: "608922568046",
  appId: "1:608922568046:web:6e1e58ec5ce16d0ac5ad7f",
  measurementId: "G-F9T7G8Y2ZX",
};

export default firebaseConfig;
