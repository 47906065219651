import styled from "styled-components";
import Header from "../Header/Header";
import background from "../Assets/marmor.png";
import { device } from "../Components/Atoms/Devices";

const PhotosContainer = styled.div`
  background: linear-gradient(
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
`;

const PhotoInfo = styled.div`
  text-align: center;
  padding: 200px 0px;
  @media ${device.mobileL} {
    padding: 100px 20px;
  }
`;

const Photos = () => {
  return (
    <PhotosContainer>
      <Header />
      <PhotoInfo>Fotos sind nach der Hochzeit verfügbar</PhotoInfo>
    </PhotosContainer>
  );
};

export default Photos;
