import styled from "styled-components";
import Header from "../Header/Header";
import background from "../Assets/marmor.png";
import { device } from "../Components/Atoms/Devices";
import campingFankhauser from "../Assets/camping-fankhauser.png";
import campingVermeille from "../Assets/camping-vermeille.png";
import dinh from "../Assets/dinh.jpg";
import post from "../Assets/post.jpg";
import vista from "../Assets/vista.jpg";
import sonnegg from "../Assets/sonnegg.png";
import golfhotel from "../Assets/golfhotel.jpg";
import booking from "../Assets/booking.jpg";
import Card from "../Components/Molecules/Card";

const OvernightContainer = styled.div`
  background-image: url(${background});
  background-repeat: repeat;
  width: 100vw;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 150px 40px;

  @media ${device.mobileL} {
    padding: 100px 10px;
  }
`;

const FlexItem = styled.div`
  flex-wrap: wrap;
  flex: 25%;

  @media ${device.tablet} {
    flex: 50%;
  }

  @media ${device.mobileL} {
    flex: 100%;
  }
`;

const Overnight = () => {
  return (
    <OvernightContainer>
      <Header />
      <FlexContainer>
        <FlexItem>
          <Card
            title="Camping Fankhauser"
            imgName={campingFankhauser}
            descripiton="Campingplatz direkt am Forellensee."
            distance="Entfernung: 5 min zu Fuss"
            price=""
            link="https://www.camping-fankhauser.ch"
          />
        </FlexItem>
        <FlexItem>
          <Card
            title="Camping Vermeille"
            imgName={campingVermeille}
            descripiton="Campingplatz direkt am Forellensee."
            distance="Entfernung: 5 min zu Fuss"
            price=""
            link="https://www.camping-vermeille.ch/index.html"
          />
        </FlexItem>
        <FlexItem>
          <Card
            title="Vista Resort Hostel"
            imgName={vista}
            descripiton="3 Sterne Hostel in Zweisimmen"
            distance="Entfernung: 25 min zu Fuss, 3 min mit dem Taxi"
            price="ab Fr. 50.- / Person"
            link="https://www.hostelvista.ch/de/vista-resort-hotel/hostel-vista-informations-generales/"
          />
        </FlexItem>
        <FlexItem>
          <Card
            title="Post Hotel Vista"
            imgName={post}
            descripiton="3 Sterne Hotel in Zweisimmen"
            distance="Entfernung: 20 min zu Fuss, 3 min mit dem Taxi"
            price="ab Fr. 100.- / Person"
            link="http://posthotelvista.ch"
          />
        </FlexItem>
        <FlexItem>
          <Card
            title="Sonnegg Hotel Garni"
            imgName={sonnegg}
            descripiton="3 Sterne Hotel in Zweisimmen"
            distance="Entfernung: 20 min zu Fuss, 4 min mit dem Taxi"
            price="ab Fr. 100.- / Person"
            link="https://www.hotel-sonnegg.ch"
          />
        </FlexItem>
        <FlexItem>
          <Card
            title="Dinh Residence Hotel"
            imgName={dinh}
            descripiton="Hotel in Zweisimmen"
            distance="Entfernung: 20 min zu Fuss, 3 min mit dem Taxi"
            price="ab Fr. 115.- / Person"
            link="https://www.dinhresidence.com"
          />
        </FlexItem>
        <FlexItem>
          <Card
            title="GOLFHOTEL Les Hauts de Gstaad & SPA"
            imgName={golfhotel}
            descripiton="4 Sterne Hotel in Gstaad"
            distance="Entfernung: 11 min mit dem Taxi"
            price="ab Fr. 329.- / Person"
            link="https://www.golfhotel.ch/de/?gclid=Cj0KCQiAkZKNBhDiARIsAPsk0Wi9-o7GppuKcZdib0zGNQi0HErA2EF48zI6qOfkL5e9C_M7LCrRyt4aAj15EALw_wcB"
          />
        </FlexItem>
        <FlexItem>
          <Card
            title="Weitere Hotels und Appartements auf Booking.com"
            imgName={booking}
            link="https://www.booking.com"
          />
        </FlexItem>
      </FlexContainer>
    </OvernightContainer>
  );
};

export default Overnight;
