import styled from "styled-components";
import Header from "../Header/Header";
import background from "../Assets/marmor.png";
import DBDownload from "../Components/Organisms/DBDownload/DBDownload";
import { device } from "../Components/Atoms/Devices";

const AdminContainer = styled.div`
  background: linear-gradient(
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
`;

const DownloadButtonContainer = styled.div`
  text-align: center;
  padding: 200px 0px;
  @media ${device.mobileL} {
    padding: 100px 20px;
  }
`;

const Admin = () => {
  return (
    <AdminContainer>
      <Header />
      <DownloadButtonContainer>
        <DBDownload collection="guestList" />
      </DownloadButtonContainer>
    </AdminContainer>
  );
};

export default Admin;
