import { createGlobalStyle, ThemeProvider } from "styled-components";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Overnight from "./Pages/Overnight";
import PhotoUpload from "./Pages/PhotoUpload";
import Register from "./Pages/Register";
import PhotoShow from "./Pages/PhotoShow";
import Photos from "./Pages/Photos";
import Admin from "./Pages/Admin";

const theme = {
  colors: {
    primary: "#9c886a",
    secundary: "#f1f1f1",
    font: "#000000",
    white: "#ffffff",
  },
  typography: {
    fontFamiliyGeneral: "Helvetica Neue",
    fontFamiliyTitle: "Snell Roundhand, cursive",
  },
};

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.typography.fontFamiliyGeneral};
  }
`;

const App = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <Switch>
          <Route path="/overnight" component={Overnight} />
          <Route path="/photoupload" component={PhotoUpload} />
          <Route path="/photoshow" component={PhotoShow} />
          <Route path="/photos" component={Photos} />
          <Route path="/register/:name" component={Register} />
          <Route path="/admin" component={Admin} />
          <Route path="/" component={Home} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
